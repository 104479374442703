import './Main.scoped.css'
import React from 'react'

function LodaingIcon(){
    return (
        <svg className="loadingItem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 314.28 208.2">
            <polygon className='door' points="309.78 150.4 309.78 150.3 309.78 150.3 256.48 4.5 10.88 4.5 64.28 150.4 10.88 203.7 256.48 203.7 309.78 150.4 309.78 150.4"></polygon>
        </svg>
    )
}

export default LodaingIcon